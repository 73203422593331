.button {
    background-color: rgb(100, 238, 100);
    display: block;
    font-size: 1.5em;
    font-weight: 700;
    padding: $padding-mobile;
    width: 100%;

    &:hover {
        background-color: rgb(163, 255, 163);
    }
}
