.car {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}

.car__title {
    font-size: 1.5em;
    margin: 0;
}

.car__selector {
    background-color: transparent;
    border: 0;
    height: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.car__status {
    visibility: hidden;

    &:checked {
        &::after {
            content: '✅';
            font-size: 5em;
            left: 50%;
            margin-left: -54px;
            margin-top: -54px;
            position: absolute;
            top: 50%;
            visibility: visible;
        }
    }
}

.car--red {
    background-image: url(/src/img/red.png);
}

.car--orange {
    background-image: url(/src/img/orange.png);
}

.car--yellow {
    background-image: url(/src/img/yellow.png);
}

.car--green {
    background-image: url(/src/img/green.png);
}

.car--blue {
    background-image: url(/src/img/blue.png);
}

.car--purple {
    background-image: url(/src/img/purple.png);
}
