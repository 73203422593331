body {
  height: 100vh;
  margin: 0;
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
}

.container {
  max-width: 800px;
  width: auto;
  margin: 0 auto;
}

.app {
  height: 85vh;
}

@media (min-width: 400px) {
  .app {
    height: auto;
  }
}

.button {
  width: 100%;
  background-color: #64ee64;
  padding: 20px;
  font-size: 1.5em;
  font-weight: 700;
  display: block;
}

.button:hover {
  background-color: #a3ffa3;
}

.car-rainbow {
  height: 67vh;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin: 20px;
  display: grid;
}

@media (min-width: 400px) {
  .car-rainbow {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin: 40px;
  }
}

.car {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.car__title {
  margin: 0;
  font-size: 1.5em;
}

.car__selector {
  height: 100%;
  width: 100%;
  background-color: #0000;
  border: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.car__status {
  visibility: hidden;
}

.car__status:checked:after {
  content: "✅";
  visibility: visible;
  margin-top: -54px;
  margin-left: -54px;
  font-size: 5em;
  position: absolute;
  top: 50%;
  left: 50%;
}

.car--red {
  background-image: url("red.e30fb31f.png");
}

.car--orange {
  background-image: url("orange.e074c2ab.png");
}

.car--yellow {
  background-image: url("yellow.c26d0477.png");
}

.car--green {
  background-image: url("green.319f4eb4.png");
}

.car--blue {
  background-image: url("blue.5d1088bf.png");
}

.car--purple {
  background-image: url("purple.6d2b540f.png");
}

.dialog {
  border-radius: 10px;
  margin: auto;
  padding: 20px;
  position: fixed;
}

.dialog::backdrop {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: #00000059;
}

.dialog__title {
  text-align: center;
  margin: 0;
  font-size: 1.8em;
}

.dialog__options {
  grid-template-columns: 1fr;
  padding: 20px;
  display: grid;
}

.game-status {
  height: 8vh;
  background-color: #efefef;
  margin: 0;
  padding: 20px;
  font-size: 1.2em;
}

.game-status dt {
  font-weight: 600;
}

.game-status dt:after {
  content: ": ";
}

.game-status dd {
  margin: 0;
}

@media (min-width: 400px) {
  .game-status {
    height: auto;
    border-radius: 5px;
    padding: 20px 40px;
    font-size: 2em;
  }
}

.game-status__item {
  grid-template-columns: 30% 60%;
  gap: 10%;
  display: grid;
}

@media (min-width: 400px) {
  .game-status__item {
    grid-template-columns: 20% 70%;
  }
}

.game-status__progress {
  width: 100%;
  height: 20px;
  border-radius: 0;
}

.game-status__progress::-webkit-progress-bar {
  background-color: #cbd3d6;
  border-radius: 7px;
}

.game-status__progress::-webkit-progress-value {
  background-color: #00ad00;
}

.heading {
  height: 15vh;
  width: 100%;
  display: inline-block;
}

@media (min-width: 400px) {
  .heading {
    height: auto;
  }
}

.heading__info {
  text-align: center;
}

h1 {
  text-align: center;
  margin: .5em 0;
  font-size: 2em;
}

@media (min-width: 400px) {
  h1 {
    font-size: 3em;
  }
}

/*# sourceMappingURL=index.c17dea3c.css.map */
