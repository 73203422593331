body {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    height: 100vh;
    margin: 0;
}

.container {
    margin: 0 auto;
    max-width: 800px;
    width: auto;
}
