.game-status {
    background-color: #efefef;
    font-size: 1.2em;
    height: $height-game-status;
    margin: 0;
    padding: $padding-mobile;

    dt {
        font-weight: 600;

        &::after {
            content: ': ';
        }
    }

    dd {
        margin: 0;
    }

    @media all and (min-width: $breakpoint-desktop) {
        font-size: 2em;
        height: auto;
        padding: $padding-mobile $padding-desktop;
        border-radius: 5px;
    }
}

.game-status__item {
    display: grid;
    grid-template-columns: 30% 60%;
    gap: 10%;

    @media all and (min-width: $breakpoint-desktop) {
        grid-template-columns: 20% 70%;
    }
}

.game-status__progress {
    border-radius: 0;
    width: 100%;
    height: 20px;

    &::-webkit-progress-bar {
        background-color: rgb(203, 211, 214);
        border-radius: 7px;
    }

    &::-webkit-progress-value {
        background-color: rgb(0, 173, 0);
    }
}
