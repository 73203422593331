.car-rainbow {
    display: grid;
    gap: $gap-mobile;
    grid-template-columns: repeat(2, 1fr);
    height: $height-car-rainbow;
    margin: $padding-mobile;

    @media all and (min-width: $breakpoint-desktop) {
        gap: $gap-desktop;
        grid-template-columns: repeat(3, 1fr);
        margin: $padding-desktop;
    }
}
